import debounce from 'lodash/debounce';

(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#blog-index', $container);

        if ($root.length === 0) return;

        // Navigation links
        (function () {
            var $links = $('#blog-index-links', $root),
                $linksInner = $('> .inner', $links);

            // Enable links dragging on small screens
            var $linksDragContainment;
            function refreshLinksDragContainment() {
                var linksWidth = $linksInner.width(),
                    overflow = Math.max(
                        0,
                        linksWidth - $linksInner.parent().width(),
                    );
                $linksDragContainment.css({
                    left: -1 * overflow + 'px',
                    width: linksWidth + overflow + 'px',
                });
                // make sure $linksInner has not moved outside containment while resizing windows
                var linksInnerPosition = $linksInner.position();
                if (
                    linksInnerPosition.left > 0 ||
                    linksInnerPosition.left < -1 * overflow
                ) {
                    $linksInner.css({
                        left:
                            linksInnerPosition.left > 0
                                ? '0'
                                : -1 * overflow + 'px',
                    });
                }
            }
            function toggleDraggableLinks() {
                if (
                    window.matchMedia(
                        '(min-width: ' + app.config.breakpoints.large + 'px)',
                    ).matches
                ) {
                    // large screens: disable drag mode
                    if ($linksDragContainment) {
                        $linksInner.draggabilly('destroy');
                        $linksDragContainment.remove();
                        $linksDragContainment = null;
                    }
                } else {
                    // small screens: enable drag mode or refresh containment
                    if ($linksDragContainment) {
                        refreshLinksDragContainment();
                    } else {
                        if ($linksInner.length !== 0) {
                            $linksDragContainment = $(
                                '<div class="travel-tour-links-drag-containment"></div>',
                            )
                                .css({
                                    position: 'absolute',
                                    top:
                                        $linksInner.position().top +
                                        parseInt(
                                            $linksInner.css('marginTop'),
                                            10,
                                        ),
                                    height: $linksInner.height() + 'px',
                                })
                                .insertBefore($linksInner);
                            refreshLinksDragContainment();
                            $linksInner.draggabilly({
                                axis: 'x',
                                containment: $linksDragContainment[0],
                            });
                        }
                    }
                }
            }
            $(window).on('resize', debounce(toggleDraggableLinks, 100));
            toggleDraggableLinks();
        })();

        // Posts init and loading
        (function () {
            var $posts = $('#blog-index-posts', $root),
                $postsList = $('> .list', $posts),
                $postsLoadmore = $('> .loadmore', $posts);

            // Load more results
            function loadmoreResults($loadmore) {
                var dict = app.getDict(),
                    $link = $('> .link', $loadmore);
                $loadmore.addClass('is-loading');
                $link.text(dict.blog.index.loading);
                app.displayMsg();
                return $.ajax({
                    method: 'get',
                    url: $link.attr('href'),
                    dataType: 'html',
                })
                    .always(function () {
                        $loadmore.removeClass('is-loading');
                    })
                    .fail(function () {
                        app.displayMsg(dict.blog.index.error);
                        $link.text(dict.blog.index.error);
                    })
                    .done(function (html) {
                        var $newPage = $(html),
                            $newPosts = $('#blog-index-posts', $newPage),
                            $newPostsList = $('> .list', $newPosts),
                            $newLoadmore = $('> .loadmore', $newPosts);
                        initPosts($newPostsList, $newLoadmore);
                        $newPostsList.children('.item').each(function (i, e) {
                            $postsList.append(e);
                        });
                        $loadmore.remove();
                        if ($newLoadmore.length > 0) {
                            $link.replaceWith($('> .link', $newLoadmore));
                            $loadmore.appendTo($posts);
                        }
                    });
            }

            // Init posts
            function initPosts($list, $loadmore) {
                $('> .item', $list).megalink({
                    selector: '.heading > .link',
                });
                $('> .link', $loadmore).on('click', function (event) {
                    event.preventDefault();
                    loadmoreResults($(this).parent());
                });
            }
            initPosts($postsList, $postsLoadmore);
        })();
    });
})(jQuery, window.app);
