(function ($) {
    var CLASSES = {
        root: 'megalink',
        proxy: 'megalink-proxy',
    };

    var EVENTS = {
        click: 'click.megalink',
    };

    $.fn.megalink = function (o) {
        if (o === 'destroy') {
            return this.each(function () {
                var $this = $(this);
                if ($this.hasClass(CLASSES.root)) {
                    $this.children('.' + CLASSES.proxy).remove();
                    $this.off(EVENTS.click, '.' + CLASSES.proxy);
                    $this.removeClass(CLASSES.root);
                }
            });
        }

        o = $.extend(
            {
                selector: 'a',
                onclick: null,
            },
            o,
        );

        return this.each(function () {
            var $this = $(this);
            if (!$this.hasClass(CLASSES.root)) {
                var $a = $(o.selector, $this).first(),
                    href = $a.attr('href') || 'javascript:void(0)',
                    target = $a.attr('target') || '_self';
                $this.append(
                    '<a class="' +
                        CLASSES.proxy +
                        '" href="' +
                        href +
                        '" target="' +
                        target +
                        '"></a>',
                );
                if (o.onclick)
                    $this.on(EVENTS.click, '.' + CLASSES.proxy, o.onclick);
                $this.addClass(CLASSES.root);
            }
        });
    };
})(jQuery);
