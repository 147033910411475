(function ($) {
    $.fn.msg = function () {
        var args = arguments,
            list,
            opts = {
                item: '<li></li>',
                close: true,
                timeout: false,
            };
        if (args.length > 0) {
            if ($.isArray(args[0])) {
                list = args[0];
                opts = $.extend(opts, args[1]);
            } else if ($.isPlainObject(args[0])) {
                list = [args[0]];
                opts = $.extend(opts, args[1]);
            } else if (typeof args[0] == 'string') {
                list = [{ text: args[0], status: args[1] || 'err' }];
                opts = $.extend(opts, args[2]);
            }
        }
        return this.each(function (i, e) {
            var $e = $(e),
                j;
            if (typeof list != 'undefined') {
                for (j in list) {
                    var m = list[j],
                        $item = $(opts.item)
                            .addClass('status-' + (m.status || 'err'))
                            .html(
                                m.text +
                                    (opts.close
                                        ? '<span class="close"></span>'
                                        : ''),
                            )
                            .hide(),
                        timer;
                    $item.appendTo($e).fadeIn();
                    if (opts.timeout) {
                        timer = setTimeout(function () {
                            $item.remove();
                        }, opts.timeout * 1000);
                    }
                    $('.close', $item).click(function () {
                        timer && clearTimeout(timer);
                        $item.remove();
                    });
                }
            } else $e.html('');
        });
    };
})(jQuery);
