(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#flat-inspiration', $container);

        if ($root.length === 0) return;

        // Megalink on nav items
        $('.flat-inspiration-nav-item', $root).megalink({
            selector: '.heading > .link',
        });
    });
})(jQuery, window.app);
