(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#flat-help', $container);

        if ($root.length === 0) return;

        var $faq = $('.flat-help-faq', $root),
            $faqNav = $('> .nav', $faq);

        function toggleSection($section) {
            $section
                .addClass('is-visible')
                .siblings('.section')
                .removeClass('is-visible');
            $faqNav
                .children('[href="#' + $section.attr('id') + '"]')
                .addClass('is-active')
                .siblings()
                .removeClass('is-active');
        }

        // Toggle FAQ sections via headings (small screens)
        $('.flat-help-faq-section > .heading', $faq)
            .on('click', function (event) {
                event.preventDefault();
                toggleSection($(this).closest('.section'));
            })
            .css('cursor', 'pointer');

        // Toggle FAQ sections via nav (large screens)
        $('> .link', $faqNav)
            .on('click', function (event) {
                event.preventDefault();
                var href = $(this).attr('href');
                toggleSection($(href));
            })
            .css('cursor', 'pointer');
    });
})(jQuery, window.app);
