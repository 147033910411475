require('jquery');

window.app.setDict('fr', {
    blog: {
        index: {
            loading: 'Chargement en cours',
            error: 'Erreur de chargement',
        },
    },
    flat: {
        home: {
            header_form_suggestions: {
                loading: 'Chargement en cours',
                error: 'Erreur de chargement',
                empty: 'Aucun résultat ne correspond à la recherche',
                destinations: {
                    singular: 'Destination',
                    plural: 'Destinations',
                },
                themes: {
                    singular: 'Sport',
                    plural: 'Sports',
                },
                tours: {
                    singular: 'Voyage',
                    plural: 'Voyages',
                },
            },
        },
    },
    travel: {
        search_engine: {
            loading: 'Chargement en cours',
            error: 'Erreur de chargement',
        },
    },
    action: {
        min_price: 'À partir de {0}',
    },
});
