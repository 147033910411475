(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('body', $container);

        if ($root.length === 0) return;

        $('.obfuscator', $root)
            .on('click', function (event) {
                event.preventDefault();
                window.open(
                    $(this).attr('data-href'),
                    $(this).attr('data-target'),
                );
            })
            .css('cursor', 'pointer');
    });
})(jQuery, window.app);
