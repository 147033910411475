(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#blog-post', $container);

        if ($root.length === 0) return;

        // Related tours
        (function () {
            var $related = $('#blog-post-related', $root),
                $relatedNav = $('> .nav', $related),
                $relatedActions = $('> .actions', $related);

            // Slideshow
            $relatedNav
                .flickity({
                    adaptiveHeight: true,
                    cellAlign: 'left',
                    groupCells: true,
                    prevNextButtons: false,
                    pageDots: false,
                })
                .captureImgLoad(function () {
                    $relatedNav.flickity('resize');
                });

            var flickity = $relatedNav.data('flickity');
            if (
                flickity != null &&
                flickity.slides != null &&
                flickity.slides.length > 1
            ) {
                function toggleActionButtons() {
                    $('> .action-prev', $relatedActions).toggleClass(
                        'is-disabled',
                        flickity.selectedIndex < 1,
                    );
                    $('> .action-next', $relatedActions).toggleClass(
                        'is-disabled',
                        flickity.selectedIndex >= flickity.slides.length - 1,
                    );
                }
                $relatedNav.on('select.flickity', toggleActionButtons);
                toggleActionButtons();
                $relatedActions
                    .on('click', '.action-prev', function () {
                        $relatedNav.flickity('previous');
                    })
                    .on('click', '.action-next', function () {
                        $relatedNav.flickity('next');
                    });
                $related.addClass('has-actions');
            }

            // Megalink on related items
            $('.blog-post-related-item', $relatedNav).megalink({
                selector: '.heading > .link',
            });
        })();
    });
})(jQuery, window.app);
