require('jquery');

(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#flat-home', $container);

        if ($root.length === 0) return;

        // Informations complementaires
        (function () {
            var $infoContent = $('.flat-home-info > .content', $root);
            var $infoShowMore = $('#show-more', $root);
            var $infoShowLess = $('#show-less', $root);

            $infoShowMore.on('click', () => {
                $infoContent.addClass('content-more');
                $infoContent.removeClass('content-less');
                $infoShowMore.toggleClass('hidden');
                $infoShowLess.toggleClass('hidden');
            });

            $infoShowLess.on('click', () => {
                $infoContent.addClass('content-less');
                $infoContent.removeClass('content-more');
                $infoShowLess.toggleClass('hidden');
                $infoShowMore.toggleClass('hidden');
            });
        })();
    });
})(jQuery, window.app);
