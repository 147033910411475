(function ($) {
    var ns = 'quicktip';
    function prefix(ev) {
        return ev
            .split(/\s+/)
            .map(function (e) {
                return $.trim(e) + '.' + ns;
            })
            .join(' ');
    }
    function attach(e, o) {
        if (!$.isPlainObject(o)) o = { text: o };
        o = $.extend(
            {
                class: '',
                text: 'Default text',
                position: 'top',
            },
            o,
        );
        var $e = $(e),
            $tip = $(
                '<div class="' +
                    [ns, o.position, o.class].join(' ') +
                    '">' +
                    o.text +
                    '</div>',
            );
        detach(e);
        $e.data(ns, $tip).addClass('has-' + ns);
        $tip.css({
            position: 'absolute',
            display: 'none',
        }).appendTo('body');
        $('html').css('position', 'relative'); // important
        $e.on(prefix('mouseenter focusin'), function () {
            var $e = $(this),
                $tip = $e.data(ns);
            $tip.css(
                $tip.hasClass('top')
                    ? {
                          left:
                              $e.offset().left +
                              0.5 * $e.outerWidth(true) +
                              'px',
                          bottom: $('body').height() - $e.offset().top + 'px',
                      }
                    : {
                          left:
                              $e.offset().left +
                              0.5 * $e.outerWidth(true) +
                              'px',
                          top: $e.offset().top + $e.outerHeight(true) + 'px',
                      },
            ).show();
        }).on(prefix('mouseleave focusout'), function () {
            var $e = $(this);
            if (!$e.is(':focus')) $e.data(ns).hide();
        });
        if ($e.is(':focus')) $tip.show();
    }
    function detach(el) {
        $(el).each(function (i, e) {
            var $e = $(e),
                $tip = $e.data(ns);
            if ($tip) {
                if ($tip.length > 0) $tip.remove();
                $e.removeData(ns).removeClass('has-' + ns);
                $e.off('.' + ns);
            }
        });
    }
    $.fn[ns] = function (o) {
        return this.each(function (i, e) {
            attach(e, o);
        });
    };
    function Public() {
        this.attach = function (e, o) {
            $(e).each(function (i, e) {
                attach(e, o);
            });
        };
        this.detach = function (e) {
            $(e).each(function (i, e) {
                detach(e);
            });
        };
    }
    $[ns] = new Public();
})(jQuery);
