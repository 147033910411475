(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#travel-search-engine', $container);

        if ($root.length === 0) return;

        var $form = $('#travel-search-engine-form', $root),
            redirectConditions = $form.data('redirect'),
            $selection = $('#travel-search-engine-selection', $root),
            $results = $('#travel-search-engine-results', $root);

        if ($results.length === 0) {
            //Landing Filter
            $results = $('#travel-search-engine-results', $container);
        }

        var $resultsSortSelect = $('> .header > .sort > select', $results),
            $resultsList = $results.children('.list'),
            $loadmore = $results.children('.loadmore'),
            $loadmoreLink = $('>.link', $loadmore),
            $loadmoreLinkSpan = $('> span', $loadmoreLink);

        // Init form
        function initForm() {
            var $formToggle = $('> .toggle', $form),
                $formFiltersLinkLabels = $('> .filter > .label.is-link', $form),
                $formReset = $('> .reset', $form);

            // Toggle filters
            $('.action-toggle', $formToggle).on('click', function () {
                $form.toggleClass('is-visible');
            });

            // List filters interactions
            $('> .filter > .list', $form).each(function (i, e) {
                var $list = $(e),
                    $linkLabel = $list.prev('.label.is-link');

                if ($linkLabel.length > 0) {
                    // On click on link label (if exists), toggle state
                    function toggleLinkLabel(status) {
                        $linkLabel.toggleClass('is-active', status);
                        if ($linkLabel.hasClass('is-active')) {
                            $formFiltersLinkLabels
                                .not($linkLabel)
                                .removeClass('is-active');
                        }
                    }
                    $linkLabel.on('click', function () {
                        toggleLinkLabel(); // toggle
                    });

                    //
                    $list.on('change', function () {
                        toggleLinkLabel(false); // close
                    });
                }
            });

            // Reset filters
            $('.action-reset', $formReset).on('click', function () {
                $('> .filter', $form).each(function (i, e) {
                    var $list = $('> .list', e);
                    if ($list.length > 0) {
                        $('[name]:checked', $list).each(function (ii, ee) {
                            $(ee)
                                .prop('checked', false)
                                .triggerHandler('change'); // don't trigger `change` here
                        });
                    }
                });
                $form.trigger('submit'); // trigger `change` here
            });
        }
        initForm();

        // Init results sort select: transfer 'change' event
        $resultsSortSelect.on('change', function () {
            $form.trigger('submit');
        });

        // Refresh results and filters
        function refreshResultsAndFilters(html, clearResultsList) {
            var $newPage = $(html),
                $newResults = $('#travel-search-engine-results', $newPage),
                $newResultsSortSelect = $(
                    '> .header > .sort > select',
                    $newResults,
                ),
                $newResultsList = $newResults.children('.list'),
                $newLoadmore = $newResults.children('.loadmore'),
                $newLoadmoreLink = $('>.link', $newLoadmore);
            app.trigger('dom:ready', $form);
            initForm();
            if (clearResultsList) $resultsList.html('');
            $newResultsList.children('.result').each(function (i, e) {
                $resultsList.append(e);
            });
            app.trigger('dom:ready', $resultsList);
            $resultsSortSelect.val($newResultsSortSelect.val());

            if ($newLoadmore.length === 0) $loadmore.addClass('hidden');
            else {
                $loadmoreLink.attr('href', $newLoadmoreLink.attr('href'));
                $loadmoreLinkSpan.text($newLoadmoreLink.text());
            }
        }

        // Fetch ajax response
        function fetchAjaxResponse(method, url, data) {
            var dict = app.getDict();
            $loadmoreLinkSpan.text(dict.travel.search_engine.loading);
            app.displayMsg();
            return $.ajax({
                method: method,
                url: url,
                dataType: 'html',
                data: data,
            })
                .always(function () {
                    $form.add($selection).add($results);
                })
                .fail(function () {
                    app.displayMsg(dict.travel.search_engine.error);
                    $loadmoreLink.text(dict.travel.search_engine.error);
                });
        }

        // If redirect conditions are set, redirect the page
        $form.on('submit', function (event) {
            if (redirectConditions) {
                var $redirectFilter = $(
                        '[name="' + redirectConditions.filter + '"]:checked',
                        $form,
                    ),
                    redirectValue = parseInt($redirectFilter.val(), 10) || null;

                if (
                    $redirectFilter.length !== 1 ||
                    redirectValue !== redirectConditions.value
                ) {
                    event.preventDefault();
                    // the reference filter has changed => we trigger redirection
                    app.reroute(
                        redirectConditions.url +
                            '?' +
                            $form.serialize() +
                            '#travel-search-engine',
                    );
                }
            }
        });

        // On loadmore click, refresh results and filters without clearing list
        $loadmoreLink.on('click', function (event) {
            event.preventDefault();
            fetchAjaxResponse('get', $loadmoreLink.attr('href')).done(
                function (html) {
                    refreshResultsAndFilters(html, false);
                },
            );
        });

        $(document).on('click', '.filter-chip', function () {
            var $button = $(this),
                name = $button.attr('data-name'),
                value = $button.attr('data-val');
            $button.addClass('is-being-removed');
            $('[name="' + name + '[]"][value="' + value + '"]').trigger(
                'click',
            );
            $form.trigger('submit');
        });
    });
})(jQuery, window.app);
